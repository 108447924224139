export function getHead({ $route, $store }, i18nHead) {
  // Get full path
  const { path } = $route
  const fullPath = process.env.BASE_URL + path

  // Get layout
  const layout = $store.getters['layout/getLayout']
  const {
    scripts,
    environment: { environment }
  } = layout || {}
  const { scripts: enabled, google_site_verification } = scripts

  // Create static head
  const head = {
    htmlAttrs: {
      ...i18nHead.htmlAttrs
    },
    meta: [
      ...i18nHead.meta,
      {
        hid: 'og:url',
        property: 'og:url',
        content: fullPath
      },
      {
        name: 'robots',
        content:
          environment !== 'homolog' ? 'index, follow' : 'noindex, nofollow'
      }
    ],
    link: [...i18nHead.link],
    script: [],
    noscript: []
  }

  // Add dynamic head properties
  if (enabled === '1' && environment !== 'homolog') {
    // Add Google Site Verification script
    if (google_site_verification) {
      head.meta.push({
        hid: 'google-site-verification',
        name: 'google-site-verification',
        content: google_site_verification
      })
    }
  }

  return head
}
