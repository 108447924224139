// Dependencies
import ApiService from './api.service'

/**
 * Management of Newsletter component
 * @export
 * @class NewsletterService
 * @extends {ApiService}
 */

export default class NewsletterService extends ApiService {
  /**
   * Creates an instance of NewsletterService.
   * @param {Object} { $axios }
   * @memberof NewsletterService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Send Newsletter resource
   * @param {FormData} data
   * @return {Response}
   * @memberof NewsletterService
   */
  async send(data) {
    try {
      const url = '/web/newsletter'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao cadastrar uma newsletter')
      throw err
    }
  }
}
