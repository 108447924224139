var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cookies-usage",class:[
    {
      show: _vm.canShowAlert,
      opened: !_vm.accepted || _vm.forceShowAlert
    }
  ]},[_c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"cookies-usage-controller",class:[
        _vm.cookies.cookies_notification_controller &&
        _vm.cookies.cookies_notification_controller !== 'none'
          ? _vm.cookies.cookies_notification_controller
          : 'left',
        {
          show:
            _vm.cookies.cookies_notification_controller !== 'none' || !_vm.accepted
        }
      ],on:{"click":_vm.toggleCookiesAlert}},[_c('div',{staticClass:"content"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" cookie ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('title')))])])])]),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[((!_vm.showOptions && !_vm.accepted) || (!_vm.showOptions && _vm.forceShowAlert))?_c('div',{staticClass:"cookies-usage-box",class:_vm.cookies.cookies_notification_position || 'left'},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('span',{staticClass:"material-icons-outlined hidden"},[_vm._v(" cookie ")]),_vm._v(" "),_c('div',[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.textMessage)}}),_vm._v(" "),(_vm.privacy.privacy_policy_link || _vm.privacy.terms_of_use_link)?_c('div',{staticClass:"terms"},[_c('ul',[(_vm.privacy.privacy_policy_link)?_c('li',[_c('a',{staticClass:"link",attrs:{"href":_vm.localePath('/politicas-de-privacidade'),"target":"_blank","rel":"noreferrer"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('privacy.privacy_policy_link')))])])]):_vm._e(),_vm._v(" "),(_vm.privacy.terms_of_use_link)?_c('li',[_c('a',{staticClass:"link",attrs:{"href":_vm.localePath('/termos-de-uso'),"target":"_blank","rel":"noreferrer"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('privacy.terms_of_use_link')))])])]):_vm._e()])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"buttons",class:{
            'buttons-simple':
              _vm.cookies.cookies_notification_version === 'simple'
          }},[(
              _vm.cookies.cookies_notification_version === 'complete' &&
              _vm.cookiesList.length > 0
            )?_c('button',{staticClass:"btn btn-primary-outline",attrs:{"type":"button"},on:{"click":_vm.toggleOptions}},[_vm._v("\n            "+_vm._s(_vm.$t('options'))+"\n          ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.accept}},[_vm._v("\n            "+_vm._s(_vm.$t('accept'))+"\n          ")])])])]):_vm._e()]),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[((_vm.showOptions && !_vm.accepted) || (_vm.showOptions && _vm.forceShowAlert))?_c('div',{staticClass:"cookies-usage-options",class:_vm.cookies.cookies_notification_controller &&
        _vm.cookies.cookies_notification_controller !== 'none'
          ? _vm.cookies.cookies_notification_controller
          : 'left'},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.$t('usage_options.title')))])]),_vm._v(" "),_c('div',{staticClass:"close-button"},[_c('button',{on:{"click":_vm.toggleOptions}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" close ")])])])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('ul',_vm._l((_vm.cookiesList),function(category,categoryIndex){return _c('div',{key:("category-" + (category.category))},[_c('li',{staticClass:"option-item"},[_c('div',{staticClass:"option-title",on:{"click":function($event){return _vm.toggleSubOptions(categoryIndex)}}},[_vm._v("\n                "+_vm._s(category.categoryLabel)+"\n                ("+_vm._s(category.items.length)+")\n              ")])]),_vm._v(" "),_c('Transition',{attrs:{"name":"slide-fade"}},[(category.showSubOptions)?_c('div',_vm._l((category.items),function(cookie,cookieIndex){return _c('li',{key:("category-" + (category.category) + "-" + cookieIndex),staticClass:"sub-option-item"},[_c('div',{staticClass:"sub-option-item-title",class:{ 'opacity-70': !cookie.optional }},[_vm._v("\n                    "+_vm._s(cookie.title)+"\n                    "),(cookie.description)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: cookie.description,
                        trigger: 'hover',
                        delay: { show: 200, hide: 200 }
                      }),expression:"{\n                        content: cookie.description,\n                        trigger: 'hover',\n                        delay: { show: 200, hide: 200 }\n                      }"}],attrs:{"type":"button"}},[_c('span',{staticClass:"material-icons text-sm mt-5"},[_vm._v(" info ")])]):_vm._e()]),_vm._v(" "),_c('div',[_c('ClientOnly',[_c('ToggleButton',{attrs:{"sync":true,"labels":{
                          checked: ("" + (_vm.$t('usage_options.active'))),
                          unchecked: ("" + (_vm.$t('usage_options.inactive')))
                        },"disabled":!cookie.optional},model:{value:(cookie.activated),callback:function ($$v) {_vm.$set(cookie, "activated", $$v)},expression:"cookie.activated"}})],1)],1)])}),0):_vm._e()])],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.savePreferences}},[_vm._v("\n          "+_vm._s(_vm.$t('usage_options.button'))+"\n        ")])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }