//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Plugins
import { getHead } from '~/plugins/head.plugin'

export default {
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return getHead(this, i18nHead)
  }
}
