// https://github.com/nuxt-community/gtm-module
export default function ({ store, $helpers, $gtm }) {
  // Get cookie preference
  const cookiePreference = $helpers.findCookiePreference('google-tag-manager')
  const cookieActived = cookiePreference ? cookiePreference.activated : true

  // Get layout data
  const layout = store.getters['layout/getLayout']

  // Get layout scripts and environment
  const {
    scripts,
    environment: { environment }
  } = layout || {}

  // Get script configs
  const { scripts: enabled, google_tag_manager } = scripts

  // Should insert script on page?
  const insertScript =
    enabled === '1' &&
    environment !== 'homolog' &&
    google_tag_manager &&
    cookieActived

  // Handle script not inserted
  if (!insertScript) {
    console.log('Google Tag Manager script not inserted!')
    return
  }

  // Insert GTM
  $gtm.init(google_tag_manager)

  // Log
  console.log('Google Tag Manager script inserted!')
}
