//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'clean',

  props: {
    error: {
      type: [Number, String, Boolean, Object],
      required: true
    }
  },

  head() {
    return {
      title: `${this.title} - ${process.env.PROJECT_NAME}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description
        }
      ]
    }
  },

  computed: {
    title() {
      if (this.error.statusCode === 404) {
        return 'Página não encontrada'
      } else if (this.error.statusCode === 500) {
        return 'Ocorreu algum problema interno'
      } else if (this.error.statusCode === 502) {
        return 'Site em manutenção'
      } else {
        return 'Oops! Ocorreu um erro'
      }
    },

    description() {
      if (this.error.statusCode === 404) {
        return `Parece que a página que você está tentando acessar<br />
        não existe ou foi movida. Por favor verifique a URL e<br />
        tente novamente.`
      } else if (this.error.statusCode === 500) {
        return `A página que você está procurando está<br />
        temporariamente indisponível.`
      } else if (this.error.statusCode === 502) {
        return `O site está em manutenção no momento.<br />
        Por favor, tente novamente mais tarde.`
      } else {
        return `Ocorreu um erro interno e não possível carregar<br />
        está página.`
      }
    }
  }
}
