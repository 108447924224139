// Services
import ApiService from '~/services/api.service'
import AuthService from '~/services/auth.service'
import LayoutService from '~/services/layout.service'
import SeoService from '~/services/seo.service'
import HomeService from '~/services/home.service'
import NewsletterService from '~/services/newsletter.service'
import InstitutionalService from '~/services/institutional.service'
import NewsService from '~/services/news.service'
import SolutionsService from '~/services/solutions.service'
import ContactService from '~/services/contact.service'
import WorkWithUsService from '~/services/work-with-us.service'
import ProductsService from '~/services/products.service'
import KnowledgeBaseService from '~/services/knowledge-base.service'
import SectionsService from '~/services/sections.service'

// Inject the services, making it available in the context, component, store, etc.
export default (ctx, inject) => {
  const services = {
    instance: new ApiService(ctx),
    layout: new LayoutService(ctx),
    seo: new SeoService(ctx),
    institutional: new InstitutionalService(ctx),
    news: new NewsService(ctx),
    home: new HomeService(ctx),
    newsletter: new NewsletterService(ctx),
    solutions: new SolutionsService(ctx),
    contact: new ContactService(ctx),
    workWithUs: new WorkWithUsService(ctx),
    products: new ProductsService(ctx),
    auth: new AuthService(ctx),
    knowledgeBase: new KnowledgeBaseService(ctx),
    sections: new SectionsService(ctx)
  }

  ctx.$api = services
  inject('api', services)
}
