// Dependencies
import ApiService from './api.service'

/**
 * Management of WorkWithUs component
 * @export
 * @class WorkWithUsService
 * @extends {ApiService}
 */

export default class WorkWithUsService extends ApiService {
  /**
   * Creates an instance of WorkWithUsService.
   * @param {Object} { $axios }
   * @memberof WorkWithUsService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Send WorkWithUs resource
   * @param {FormData} data
   * @return {Response}
   * @memberof WorkWithUsService
   */
  async send(data) {
    try {
      const url = '/web/work-with-us'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao cadastrar um currículo')
      throw err
    }
  }

  /**
   * List Area of Interest options
   * @return {Response}
   * @memberof WorkWithUsService
   */
  async showAreaOfInterest() {
    try {
      const url = '/web/work-with-us/interest'
      const response = await this.get({ url })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao listar as áreas de interesse')
      throw err
    }
  }

  /**
   * List Education Level options
   * @return {Response}
   * @memberof WorkWithUsService
   */
  async showEducationLevel() {
    try {
      const url = '/web/work-with-us/education-level'
      const response = await this.get({ url })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao listar os níveis de escolaridade')
      throw err
    }
  }

  /**
   * List Training Level options
   * @return {Response}
   * @memberof WorkWithUsService
   */
  async showTrainingLevel(type) {
    try {
      const url = `/web/work-with-us/training-level?type=${type}`
      const response = await this.get({ url })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao listar as áreas de formação')
      throw err
    }
  }
}
