// Dependencies
import ApiService from './api.service'

/**
 * Management of Layout Data
 * @export
 * @class LayoutService
 * @extends {ApiService}
 */
export default class LayoutService extends ApiService {
  /**
   * Creates an instance of LayoutService.
   * @param {Object} { $axios, store }
   * @memberof LayoutService
   */
  constructor({ $axios, store }) {
    super({ $axios })
    this.store = store
  }

  /**
   * Show layout resource
   * @return {Response}
   * @memberof LayoutService
   */
  async show() {
    try {
      const url = '/web/layout'
      const response = await this.get({
        url,
        extraConfig: {
          progress: false
        }
      })

      await this.store.dispatch('layout/storeLayout', response)

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar os dados do layout')
      throw err
    }
  }
}
