//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'vodal/common.css'
import Vodal from 'vodal'
import { mapGetters } from 'vuex'

export default {
  components: {
    Vodal
  },

  computed: {
    ...mapGetters({
      config: 'modal/getConfig'
    })
  },

  methods: {
    handleActionClick() {
      this.$store.dispatch('modal/hide')

      if (this.config.actionUrl) {
        this.$router.push(this.config.actionUrl)
      }
    }
  }
}
