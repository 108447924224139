export const state = () => ({
  data: []
})

export const mutations = {
  SET_DATA(state, data) {
    state.data = data
  }
}

export const actions = {
  storeInitialsPopups({ commit }, data) {
    return new Promise((resolve, reject) => {
      try {
        const localeCodes = this.app.i18n.localeCodes
        const options = {
          path: '/',
          maxAge: 60 * 60 * 24 * 365
        }
        const popups = []
        const storeInitialsPopups = this.$cookies.get(
          `${process.env.STORAGE_PREFIX}storeInitialsPopups`
        )

        if (storeInitialsPopups) {
          for (let i = 0; i < localeCodes.length; i++) {
            const popup = this.$cookies.get(
              `${process.env.STORAGE_PREFIX}popup-${localeCodes[i]}`
            )

            popups.push(popup)
          }

          commit('SET_DATA', popups)
        } else {
          this.$cookies.set(
            `${process.env.STORAGE_PREFIX}storeInitialsPopups`,
            'true',
            options
          )

          for (let i = 0; i < localeCodes.length; i++) {
            const popup = {
              name: `${process.env.STORAGE_PREFIX}popup-${localeCodes[i]}`,
              value: {
                lang: localeCodes[i],
                closed: false
              },
              opts: options
            }

            popups.push(popup)
          }

          if (popups) {
            this.$cookies.setAll(popups)

            commit('SET_DATA', popups)
          }
        }

        resolve(true)
      } catch (err) {
        console.log('Ocorreu um erro ao setar os dados do popup')
        reject(err)
      }
    })
  },

  storePopup({ commit }, data) {
    return new Promise((resolve, reject) => {
      try {
        const lang = this.app.i18n.locale
        const localeCodes = this.app.i18n.localeCodes
        const options = {
          path: '/',
          maxAge: 60 * 60 * 24 * 365
        }
        const popups = []
        let popup = this.$cookies.get(
          `${process.env.STORAGE_PREFIX}popup-${lang}`
        )

        if (popup && data) {
          if (popup.updated_at) {
            popup.updated_at === data.updated_at
              ? (popup.closed = true)
              : (popup.closed = false)
          }

          if (data.close_action) {
            popup.updated_at = data.updated_at
            popup.closed = true
          }

          this.$cookies.set(
            `${process.env.STORAGE_PREFIX}popup-${lang}`,
            popup,
            options
          )

          for (let i = 0; i < localeCodes.length; i++) {
            popup = this.$cookies.get(
              `${process.env.STORAGE_PREFIX}popup-${localeCodes[i]}`
            )

            popups.push(popup)
          }

          commit('SET_DATA', popups)
        } else {
          popup = { lang, closed: false }

          this.$cookies.set(
            `${process.env.STORAGE_PREFIX}popup-${lang}`,
            popup,
            options
          )

          popups.push(popup)

          commit('SET_DATA', popups)
        }

        resolve(true)
      } catch (err) {
        console.log('Ocorreu um erro ao setar os dados do popup')
        reject(err)
      }
    })
  }
}

export const getters = {
  getPopup(state) {
    return state.data
  }
}
