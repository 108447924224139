//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  data() {
    return {
      accepted: true,
      preferences: [],
      forceShowAlert: false,
      showOptions: false,
      cookiesList: []
    }
  },

  computed: {
    ...mapGetters({
      layout: 'layout/getLayout'
    }),

    cookies() {
      const { cookies } = this.layout
      return cookies || {}
    },

    privacy() {
      const { privacy } = this.layout
      return privacy || {}
    },

    textMessage() {
      const { others } = this.layout
      const { cookies_notification_text } = this.cookies
      const { cookies_notification_text_en, cookies_notification_text_es } =
        others
      const defaultText = `<p>${this.$t('default')}</p>`

      if (this.$i18n.locale === 'en') {
        return cookies_notification_text_en || defaultText
      } else if (this.$i18n.locale === 'es') {
        return cookies_notification_text_es || defaultText
      } else {
        return cookies_notification_text || defaultText
      }
    },

    canShowAlert() {
      const { cookies_notification } = this.cookies
      return cookies_notification === '1'
    }
  },

  mounted() {
    this.getPreferences()
    this.getCookies()
  },

  methods: {
    getPreferences() {
      const savedPreferences = localStorage.getItem(
        `${process.env.STORAGE_PREFIX}cookies-usage-accepted`
      )

      if (savedPreferences) {
        const savedPreferencesParsed = JSON.parse(savedPreferences)
        const { preferences, accepted } = savedPreferencesParsed

        this.preferences = preferences || []
        this.accepted = accepted
      } else {
        this.accepted = false
        this.preferences = []
      }
    },

    getCookies() {
      try {
        const { cookies_complete: cookiesArray } = this.cookies

        if (cookiesArray && cookiesArray.length > 0) {
          this.cookiesList = cloneDeep(cookiesArray).map(
            (category, categoryIndex) => {
              const foundedPreference = this.preferences.find(
                (preference) => preference.category === category.category
              )

              category.showSubOptions = categoryIndex === 0
              category.items.forEach((cookie) => {
                if (foundedPreference) {
                  const foundedCookie = foundedPreference.items.find(
                    (cookiePreference) => cookiePreference.slug === cookie.slug
                  )
                  cookie.activated = foundedCookie
                    ? foundedCookie.activated
                    : true
                } else {
                  cookie.activated = true
                }
              })

              return category
            }
          )
        }
      } catch (error) {
        console.log('Houve um erro ao recuperar o JSON dos cookies')
      }
    },

    savePreferences() {
      this.savePreferencesOnBrowser(
        `${process.env.STORAGE_PREFIX}cookies-usage-accepted`,
        {
          accepted: false,
          preferences: this.cookiesList
        }
      )
      this.showOptions = false
    },

    savePreferencesOnBrowser(key, value) {
      localStorage.setItem(key, JSON.stringify(value))
    },

    accept() {
      this.savePreferencesOnBrowser(
        `${process.env.STORAGE_PREFIX}cookies-usage-accepted`,
        {
          accepted: true,
          preferences: this.cookiesList
        }
      )
      this.accepted = true
      this.forceShowAlert = false
    },

    toggleCookiesAlert() {
      this.forceShowAlert = !this.forceShowAlert
      this.closeAllOptions()
      this.closeAllSubOptions()
    },

    toggleOptions() {
      this.showOptions = !this.showOptions
      if (!this.showOptions) {
        this.closeAllSubOptions()
      }
    },

    closeAllOptions() {
      this.showOptions = false
    },

    toggleSubOptions(index) {
      this.cookiesList = this.cookiesList.map((category, categoryIndex) => {
        if (categoryIndex === index) {
          category.showSubOptions = !category.showSubOptions
        }
        return category
      })
    },

    closeAllSubOptions() {
      this.cookiesList = this.cookiesList.map((category) => {
        category.showSubOptions = false
        return category
      })
    }
  }
}
