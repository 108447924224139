// https://axios.nuxtjs.org/
import { isEmpty, findKey } from 'lodash'

// Translations
import enUS from '~/lang/en-US'
import esES from '~/lang/es-ES'
import ptBR from '~/lang/pt-BR'

export default function ({ $axios, app, store, redirect }) {
  $axios.onRequest(async (config) => {
    // Get token
    const token = store.getters['auth/getAuth']

    // Append token to the header request
    if (token) config.headers.Authorization = `Bearer ${token}`

    // Append Content Language to the header request
    if (app.i18n.locale) config.headers['Content-Language'] = app.i18n.locale

    // Handle with locale query params
    if (!isEmpty(config.params))
      config.params = await handleQueryParams(config.params, app.i18n)

    return config
  })

  $axios.onError((err) => {
    if (err.response) {
      if (err.response.status === 401) {
        store.dispatch('auth/logout')
        return redirect('/area-restrita')
      }
    }
  })

  function handleQueryParams(params, i18n) {
    return new Promise((resolve, reject) => {
      try {
        const newQueryParams = {}

        Object.entries(params).forEach(([key, value]) => {
          let newKey = ''

          if (i18n.locale === 'en') {
            newKey = findKey(enUS, (keyValue) => keyValue === key)
          } else if (i18n.locale === 'es') {
            newKey = findKey(esES, (keyValue) => keyValue === key)
          } else {
            newKey = findKey(ptBR, (keyValue) => keyValue === key)
          }

          newQueryParams[newKey || key] = value
        })

        resolve(newQueryParams)
      } catch (err) {
        console.log('Ocorreu um erro ao realizar o tratamento das queries', err)
        reject(params)
      }
    })
  }
}
