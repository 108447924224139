

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: true,
  locales: [{"code":"br","file":"pt-BR.js","iso":"pt-BR","title":"Português","abbr":"Por"},{"code":"en","file":"en-US.js","iso":"en-US","title":"English","abbr":"Eng"},{"code":"es","file":"es-ES.js","iso":"es-ES","title":"Español","abbr":"Esp"}],
  defaultLocale: "br",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/html/intelli.com.br/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.intelli.com.br",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: (oldLocale, newLocale, isInitial, context) => {
      const { $cookies, route, localeRoute, redirect } = context;
      const { path } = route;
      const cookieLocale = $cookies.get('i18n_redirected');

      if (path === '/' && cookieLocale && cookieLocale !== newLocale) {
        const { path: newLocalePath } = localeRoute('index', cookieLocale);
        redirect(newLocalePath);
      }
    },
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"br","file":"pt-BR.js","iso":"pt-BR","title":"Português","abbr":"Por"},{"code":"en","file":"en-US.js","iso":"en-US","title":"English","abbr":"Eng"},{"code":"es","file":"es-ES.js","iso":"es-ES","title":"Español","abbr":"Esp"}],
  localeCodes: ["br","en","es"],
}

export const localeMessages = {
  'pt-BR.js': () => import('../../lang/pt-BR.js' /* webpackChunkName: "lang-pt-BR.js" */),
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'es-ES.js': () => import('../../lang/es-ES.js' /* webpackChunkName: "lang-es-ES.js" */),
}
