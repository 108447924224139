//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    showActions: {
      type: Boolean,
      default: true
    },

    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      defaultOptions: {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        dots: false,
        edgeFriction: 0,
        touchThreshold: 1000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1.8,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 476,
            settings: {
              slidesToShow: 1.3,
              slidesToScroll: 1
            }
          }
        ]
      },
      currentSliderIndex: 0,
      slideId: ''
    }
  },

  computed: {
    slickOptions() {
      return {
        ...this.defaultOptions,
        ...this.options
      }
    }
  },

  created() {
    this.setSlideId()
  },

  methods: {
    setSlideId() {
      this.slideId = Date.now() + Math.floor(Math.random() * 100)
    },

    prevSlider() {
      const slideRef = `slideIt[${this.slideId}]`
      this.$refs[slideRef].prev()
    },

    nextSlider() {
      const slideRef = `slideIt[${this.slideId}]`
      this.$refs[slideRef].next()
    },

    onBeforeChange(currentPosition, nextPosition) {
      this.currentSliderIndex = nextPosition
    }
  }
}
