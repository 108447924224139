//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  props: {
    basePath: {
      type: String,
      default: '/base-de-conhecimento'
    },

    publication: {
      type: Object,
      default: () => {
        return {}
      }
    },

    noBtnForward: {
      type: Boolean
    }
  },
  computed: {
    publicationPath() {
      const { section, slug } = this.publication
      const sectionSlug = section ? section.slug : 'sem-secao'

      return this.localePath(`${this.basePath}/${sectionSlug}/${slug}`)
    }
  }
}
