export default async function ({ store, $api }) {
  // User is logged in
  const isLoggedIn = store.getters['auth/isLoggedIn']

  // If user is logged in call whoim on API
  if (isLoggedIn) {
    try {
      await $api.auth.whoim()
    } catch (err) {
      console.log(err)
    }
  }
}
