// Dependencies
import ApiService from './api.service'

/**
 * Management of Contact component
 * @export
 * @class ContactService
 * @extends {ApiService}
 */

export default class ContactService extends ApiService {
  /**
   * Creates an instance of ContactService.
   * @param {Object} { $axios }
   * @memberof ContactService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Send Contact resource
   * @param {FormData} data
   * @return {Response}
   * @memberof ContactService
   */
  async send(data) {
    try {
      const url = '/web/contact'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao cadastrar um contato')
      throw err
    }
  }

  /**
   * List Departments options
   * @return {Response}
   * @memberof ContactService
   */
  async showDepartments() {
    try {
      const url = '/web/contact/departments'
      const response = await this.get({ url })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao listar os departamentos')
      throw err
    }
  }
}
