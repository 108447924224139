// Dependencies
import ApiService from './api.service'

/**
 * Management of News Data
 * @export
 * @class NewsService
 * @extends {ApiService}
 */
export default class NewsService extends ApiService {
  /**
   * Creates an instance of NewsService.
   * @param {Object} { $axios }
   * @memberof NewsService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Show all news resources
   * @param {FormData} params
   * @return {Response}
   * @memberof NewsService
   */
  async showAll(params) {
    try {
      const url = '/web/news'
      const response = await this.get({
        url,
        params
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar as notícias')
      throw err
    }
  }

  /**
   * Show a news resource
   * @param {*} slug
   * @return {Response}
   * @memberof NewsService
   */
  async show(slug) {
    try {
      const url = `/web/news/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar a notícia')
      throw err
    }
  }

  /**
   * Get all news categories
   * @return {Response}
   * @memberof NewsService
   */
  async categories() {
    try {
      const url = '/web/news-categories'
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar as categorias')
      throw err
    }
  }
}
