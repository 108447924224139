// Dependencies
import ApiService from './api.service'

/**
 * Management of Products Data
 * @export
 * @class ProductsService
 * @extends {ApiService}
 */
export default class ProductsService extends ApiService {
  /**
   * Creates an instance of ProductsService.
   * @param {Object} { $axios }
   * @memberof ProductsService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Show all products resources
   * @param {FormData} params
   * @return {Response}
   * @memberof ProductsService
   */
  async showAll(params) {
    try {
      const url = '/web/products'
      const response = await this.get({
        url,
        params
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar os produtos')
      throw err
    }
  }

  /**
   * Show a product resource
   * @param {*} slug
   * @return {Response}
   * @memberof ProductsService
   */
  async show(slug) {
    try {
      const url = `/web/products/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar o produto')
      throw err
    }
  }

  /**
   * Show all products categories
   * @return {Response}
   * @memberof ProductsService
   */
  async categories() {
    try {
      const url = '/web/product-category'
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar as categorias dos produtos')
      throw err
    }
  }

  /**
   * Show a product category resource
   * @param {*} slug
   * @return {Response}
   * @memberof ProductsService
   */
  async category(slug) {
    try {
      const url = `/web/product-category/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar uma categoria do produto')
      throw err
    }
  }
}
