// Dependencies
import ApiService from './api.service'

/**
 * Management of Knowledge Base page
 * @export
 * @class KnowledgeBaseService
 * @extends {ApiService}
 */

export default class KnowledgeBaseService extends ApiService {
  /**
   * Creates an instance of KnowledgeBaseService.
   * @param {Object} { $axios }
   * @memberof knowledgeBaseService
   */
  constructor({ $axios, store }) {
    super({ $axios })
    this.store = store
  }

  /**
   * Show Knowledge Base resource
   * @return {Response}
   * @memberof KnowledgeBaseService
   */
  async showAll() {
    try {
      const url = '/web/knowledge-base'
      const response = await this.get({ url })

      return response
    } catch (err) {
      console.log(
        'Ocorreu um erro ao recuperar os dados da base de conhecimento',
        err
      )

      throw err
    }
  }

  /**
   * Show a section resource
   * @param {*} slug
   * @return {Response}
   * @memberof KnowledgeBaseService
   */
  async show(slug) {
    try {
      const url = `/web/knowledge-base/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar a seção')
      throw err
    }
  }

  /**
   * Show a publications resource
   * @param {*} slug
   * @param {*} section
   * @return {Response}
   * @memberof KnowledgeBaseService
   */
  async publication(section, slug) {
    try {
      const url = `/web/knowledge-base/${section}/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar a publicação')
      throw err
    }
  }
}
