// Store used to get static seo registered by CMS
export const state = () => {
  return {
    data: {
      title: '',
      description: ''
    }
  }
}

export const mutations = {
  SET_DATA(state, data) {
    state.data = data
  }
}

export const actions = {
  storeSeo({ commit }, data) {
    return new Promise((resolve, reject) => {
      try {
        if (data) {
          commit('SET_DATA', data)
        }
        resolve(true)
      } catch (err) {
        console.log('Ocorreu um erro ao setar os dados do SEO')
        reject(err)
      }
    })
  }
}

export const getters = {
  getSeo(state) {
    return state.data
  }
}
