//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      default: 'search'
    },

    value: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: 'Buscar por palavra-chave'
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    // types: default / outline
    type: {
      type: String,
      default: 'default'
    }
  },

  data() {
    return {
      searchText: ''
    }
  },

  created() {
    this.searchText = this.value
  },

  methods: {
    updateValue(value) {
      this.searchText = value
      this.$emit('input', value)
    },

    search() {
      this.$emit('search', this.searchText)
    }
  }
}
