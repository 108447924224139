export default function ({ store, redirect, app }) {
  // User is logged in
  const isLoggedIn = store.getters['auth/isLoggedIn']

  // If user is authenticated
  if (isLoggedIn) {
    // User is admin
    const isAdmin = store.getters['auth/isAdmin']

    // If user is admin redirect to sections
    if (isAdmin) {
      return redirect(app.localePath('/area-restrita/secoes'))
    }
  }
}
