/**
 * Auth module (authentication states and logged-in user data)
 */
export const state = () => {
  return {
    auth: null,
    user: {}
  }
}

export const mutations = {
  SET_AUTH(state, auth) {
    state.auth = auth
  },

  SET_USER(state, user) {
    state.user = user
  }
}

export const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      try {
        // Get token's
        const { token: auth } = data

        // Save JWT token
        if (auth) {
          const { token } = auth

          this.$cookies.set(
            `${process.env.STORAGE_PREFIX}authorizationToken`,
            token,
            {
              path: '/',
              maxAge: 60 * 60 * 24 * 30,
              sameSite: 'lax'
            }
          )

          commit('SET_AUTH', token)
        }

        resolve(true)
      } catch (err) {
        console.log(
          'Ocorreu um erro ao salvar os dados da sessão do usuário: ',
          err
        )
        reject(err)
      }
    })
  },

  whoim({ commit }, data) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_USER', data)
        resolve(true)
      } catch (err) {
        console.log('Ocorreu um erro ao salvar os dados do usuário: ', err)
        reject(err)
      }
    })
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        // Clear cookies
        this.$cookies.remove(
          `${process.env.STORAGE_PREFIX}authorizationToken`,
          {
            path: '/',
            maxAge: 60 * 60 * 24 * 30,
            sameSite: 'lax'
          }
        )

        // Clear store
        commit('SET_AUTH', null)
        commit('SET_USER', {})

        resolve(true)
      } catch (err) {
        console.log(
          'Ocorreu um erro ao limpar os dados da sessão do usuário: ',
          err
        )
        reject(err)
      }
    })
  }
}

export const getters = {
  isLoggedIn(state) {
    return !!state.auth
  },

  getAuth(state) {
    return state.auth
  },

  getUser(state) {
    return state.user
  },

  getProfile(state) {
    return state.user && state.user.profileSlug
  },

  isAdmin(state) {
    return state.user && state.user.admin
  }
}
