export default {
  search: 'buscar',
  page: 'pagina',
  orderBy: 'ordernarPor',
  order: 'ordernar',
  desc_relevance: 'Maior Relevância',
  asc_relevance: 'Menor Relevância',
  asc_alphabetical: 'Alfabética (A-Z)',
  desc_alphabetical: 'Alfabética (Z-A)'
}
