export default {
  search: 'search',
  page: 'page',
  orderBy: 'orderBy',
  order: 'order',
  desc_relevance: 'Greater Relevance',
  asc_relevance: 'Minor Relevance',
  asc_alphabetical: 'Alphabetical (A-Z)',
  desc_alphabetical: 'Alphabetical (A-Z)'
}
