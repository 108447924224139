export const AuthLogin = () => import('../../components/auth/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthPasswordForgot = () => import('../../components/auth/PasswordForgot.vue' /* webpackChunkName: "components/auth-password-forgot" */).then(c => wrapFunctional(c.default || c))
export const AuthPasswordUpdate = () => import('../../components/auth/PasswordUpdate.vue' /* webpackChunkName: "components/auth-password-update" */).then(c => wrapFunctional(c.default || c))
export const AuthProfile = () => import('../../components/auth/Profile.vue' /* webpackChunkName: "components/auth-profile" */).then(c => wrapFunctional(c.default || c))
export const AuthRegister = () => import('../../components/auth/Register.vue' /* webpackChunkName: "components/auth-register" */).then(c => wrapFunctional(c.default || c))
export const AuthRegisterFull = () => import('../../components/auth/RegisterFull.vue' /* webpackChunkName: "components/auth-register-full" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage404 = () => import('../../components/error/Page404.vue' /* webpackChunkName: "components/error-page404" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage500 = () => import('../../components/error/Page500.vue' /* webpackChunkName: "components/error-page500" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage502 = () => import('../../components/error/Page502.vue' /* webpackChunkName: "components/error-page502" */).then(c => wrapFunctional(c.default || c))
export const BaseAlertsCookies = () => import('../../components/base/alerts/Cookies.vue' /* webpackChunkName: "components/base-alerts-cookies" */).then(c => wrapFunctional(c.default || c))
export const BaseAlertsEnvironment = () => import('../../components/base/alerts/Environment.vue' /* webpackChunkName: "components/base-alerts-environment" */).then(c => wrapFunctional(c.default || c))
export const BaseLayoutFooter = () => import('../../components/base/layout/Footer.vue' /* webpackChunkName: "components/base-layout-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseLayoutHeader = () => import('../../components/base/layout/Header.vue' /* webpackChunkName: "components/base-layout-header" */).then(c => wrapFunctional(c.default || c))
export const BaseLayoutHeaderSystem = () => import('../../components/base/layout/HeaderSystem.vue' /* webpackChunkName: "components/base-layout-header-system" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideButtons = () => import('../../components/base/styleguide/Buttons.vue' /* webpackChunkName: "components/base-styleguide-buttons" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideCards = () => import('../../components/base/styleguide/Cards.vue' /* webpackChunkName: "components/base-styleguide-cards" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideCarousels = () => import('../../components/base/styleguide/Carousels.vue' /* webpackChunkName: "components/base-styleguide-carousels" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideColors = () => import('../../components/base/styleguide/Colors.vue' /* webpackChunkName: "components/base-styleguide-colors" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideContentOrganizer = () => import('../../components/base/styleguide/ContentOrganizer.vue' /* webpackChunkName: "components/base-styleguide-content-organizer" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideFontSizes = () => import('../../components/base/styleguide/FontSizes.vue' /* webpackChunkName: "components/base-styleguide-font-sizes" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideFontWeights = () => import('../../components/base/styleguide/FontWeights.vue' /* webpackChunkName: "components/base-styleguide-font-weights" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideForms = () => import('../../components/base/styleguide/Forms.vue' /* webpackChunkName: "components/base-styleguide-forms" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideGrid = () => import('../../components/base/styleguide/Grid.vue' /* webpackChunkName: "components/base-styleguide-grid" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideLinks = () => import('../../components/base/styleguide/Links.vue' /* webpackChunkName: "components/base-styleguide-links" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideNotifications = () => import('../../components/base/styleguide/Notifications.vue' /* webpackChunkName: "components/base-styleguide-notifications" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideOthers = () => import('../../components/base/styleguide/Others.vue' /* webpackChunkName: "components/base-styleguide-others" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideSpacings = () => import('../../components/base/styleguide/Spacings.vue' /* webpackChunkName: "components/base-styleguide-spacings" */).then(c => wrapFunctional(c.default || c))
export const BaseStyleguideTypography = () => import('../../components/base/styleguide/Typography.vue' /* webpackChunkName: "components/base-styleguide-typography" */).then(c => wrapFunctional(c.default || c))
export const BaseUiBadge = () => import('../../components/base/ui/Badge.vue' /* webpackChunkName: "components/base-ui-badge" */).then(c => wrapFunctional(c.default || c))
export const BaseUiBanners = () => import('../../components/base/ui/Banners.vue' /* webpackChunkName: "components/base-ui-banners" */).then(c => wrapFunctional(c.default || c))
export const BaseUiBreadcrumb = () => import('../../components/base/ui/Breadcrumb.vue' /* webpackChunkName: "components/base-ui-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const BaseUiBreadcrumbBanner = () => import('../../components/base/ui/BreadcrumbBanner.vue' /* webpackChunkName: "components/base-ui-breadcrumb-banner" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardApplications = () => import('../../components/base/ui/CardApplications.vue' /* webpackChunkName: "components/base-ui-card-applications" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardApplicationsTool = () => import('../../components/base/ui/CardApplicationsTool.vue' /* webpackChunkName: "components/base-ui-card-applications-tool" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardBoard = () => import('../../components/base/ui/CardBoard.vue' /* webpackChunkName: "components/base-ui-card-board" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardNews = () => import('../../components/base/ui/CardNews.vue' /* webpackChunkName: "components/base-ui-card-news" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardProduct = () => import('../../components/base/ui/CardProduct.vue' /* webpackChunkName: "components/base-ui-card-product" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardPublication = () => import('../../components/base/ui/CardPublication.vue' /* webpackChunkName: "components/base-ui-card-publication" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardSolution = () => import('../../components/base/ui/CardSolution.vue' /* webpackChunkName: "components/base-ui-card-solution" */).then(c => wrapFunctional(c.default || c))
export const BaseUiCardSubcategory = () => import('../../components/base/ui/CardSubcategory.vue' /* webpackChunkName: "components/base-ui-card-subcategory" */).then(c => wrapFunctional(c.default || c))
export const BaseUiFileUploader = () => import('../../components/base/ui/FileUploader.vue' /* webpackChunkName: "components/base-ui-file-uploader" */).then(c => wrapFunctional(c.default || c))
export const BaseUiFiles = () => import('../../components/base/ui/Files.vue' /* webpackChunkName: "components/base-ui-files" */).then(c => wrapFunctional(c.default || c))
export const BaseUiGallery = () => import('../../components/base/ui/Gallery.vue' /* webpackChunkName: "components/base-ui-gallery" */).then(c => wrapFunctional(c.default || c))
export const BaseUiLanguages = () => import('../../components/base/ui/Languages.vue' /* webpackChunkName: "components/base-ui-languages" */).then(c => wrapFunctional(c.default || c))
export const BaseUiMenu = () => import('../../components/base/ui/Menu.vue' /* webpackChunkName: "components/base-ui-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseUiMenuCategories = () => import('../../components/base/ui/MenuCategories.vue' /* webpackChunkName: "components/base-ui-menu-categories" */).then(c => wrapFunctional(c.default || c))
export const BaseUiModal = () => import('../../components/base/ui/Modal.vue' /* webpackChunkName: "components/base-ui-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseUiModalAction = () => import('../../components/base/ui/ModalAction.vue' /* webpackChunkName: "components/base-ui-modal-action" */).then(c => wrapFunctional(c.default || c))
export const BaseUiModalPopup = () => import('../../components/base/ui/ModalPopup.vue' /* webpackChunkName: "components/base-ui-modal-popup" */).then(c => wrapFunctional(c.default || c))
export const BaseUiModalVideo = () => import('../../components/base/ui/ModalVideo.vue' /* webpackChunkName: "components/base-ui-modal-video" */).then(c => wrapFunctional(c.default || c))
export const BaseUiMultiselect = () => import('../../components/base/ui/Multiselect.vue' /* webpackChunkName: "components/base-ui-multiselect" */).then(c => wrapFunctional(c.default || c))
export const BaseUiNewsletter = () => import('../../components/base/ui/Newsletter.vue' /* webpackChunkName: "components/base-ui-newsletter" */).then(c => wrapFunctional(c.default || c))
export const BaseUiNoResultsFounded = () => import('../../components/base/ui/NoResultsFounded.vue' /* webpackChunkName: "components/base-ui-no-results-founded" */).then(c => wrapFunctional(c.default || c))
export const BaseUiPagination = () => import('../../components/base/ui/Pagination.vue' /* webpackChunkName: "components/base-ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const BaseUiProductsHighlightSlider = () => import('../../components/base/ui/ProductsHighlightSlider.vue' /* webpackChunkName: "components/base-ui-products-highlight-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseUiSearch = () => import('../../components/base/ui/Search.vue' /* webpackChunkName: "components/base-ui-search" */).then(c => wrapFunctional(c.default || c))
export const BaseUiSearchModalContent = () => import('../../components/base/ui/SearchModalContent.vue' /* webpackChunkName: "components/base-ui-search-modal-content" */).then(c => wrapFunctional(c.default || c))
export const BaseUiShareOnSocialMedias = () => import('../../components/base/ui/ShareOnSocialMedias.vue' /* webpackChunkName: "components/base-ui-share-on-social-medias" */).then(c => wrapFunctional(c.default || c))
export const BaseUiSlideIt = () => import('../../components/base/ui/SlideIt.vue' /* webpackChunkName: "components/base-ui-slide-it" */).then(c => wrapFunctional(c.default || c))
export const BaseUiSocialMedias = () => import('../../components/base/ui/SocialMedias.vue' /* webpackChunkName: "components/base-ui-social-medias" */).then(c => wrapFunctional(c.default || c))
export const BaseUiTimeline = () => import('../../components/base/ui/Timeline.vue' /* webpackChunkName: "components/base-ui-timeline" */).then(c => wrapFunctional(c.default || c))
export const BaseUiVideos = () => import('../../components/base/ui/Videos.vue' /* webpackChunkName: "components/base-ui-videos" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
