// https://www.npmjs.com/package/vue2-filters
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import striptags from 'striptags'
import * as entities from 'entities'

Vue.use(Vue2Filters)

Vue.filter('stripText', (text) => {
  if (text) {
    const strippedText = striptags(text)
    const strippedHtmlEntities = entities.decodeHTML(strippedText)
    return strippedHtmlEntities
  }

  return text
})

Vue.filter('truncateText', (text, length) => {
  if (text) {
    const strippedText = striptags(text)
    const strippedHtmlEntities = entities.decodeHTML(strippedText)
    return Vue.options.filters.truncate(strippedHtmlEntities, length)
  }
  return text
})
