// Dependencies
import ApiService from './api.service'

/**
 * Management of Institutional Data
 * @export
 * @class InstitutionalService
 * @extends {ApiService}
 */
export default class InstitutionalService extends ApiService {
  /**
   * Creates an instance of InstitutionalService.
   * @param {Object} { $axios }
   * @memberof InstitutionalService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Show institutional resource
   * @return {Response}
   * @memberof InstitutionalService
   */
  async show() {
    try {
      const url = '/web/institutional'
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log(
        'Ocorreu um erro ao recuperar os dados da página Institucional.'
      )
      throw err
    }
  }
}
