// Dependencies
import ApiService from './api.service'

/**
 * Management of Layout Data
 * @export
 * @class SeoService
 * @extends {ApiService}
 */
export default class SeoService extends ApiService {
  /**
   * Creates an instance of SeoService.
   * @param {Object} { $axios, store }
   * @memberof SeoService
   */
  constructor({ $axios, store }) {
    super({ $axios })
    this.store = store
  }

  /**
   * Show layout resource
   * @return {Response}
   * @memberof SeoService
   */
  async show(path) {
    try {
      const url = '/web/seo'
      const response = await this.get({
        url,
        params: {
          url: path
        },
        extraConfig: {
          progress: false
        }
      })

      await this.store.dispatch('seo/storeSeo', response)

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar os dados do seo')
      throw err
    }
  }
}
