// https://github.com/Akryum/v-tooltip#usage
import Vue from 'vue'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

VTooltip.options.defaultBoundariesElement = 'body'
VTooltip.options.defaultPlacement = 'top-center'
VTooltip.options.defaultOffset = 5
VTooltip.options.defaultHideOnTargetClick = false

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)

Vue.component('VPopover', VPopover)
