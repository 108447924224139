// https://www.npmjs.com/package/vue-gtag
import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ store, $helpers }) => {
  // Get cookie preference
  const cookiePreference = $helpers.findCookiePreference('google-analytics')
  const cookieActived = cookiePreference ? cookiePreference.activated : true

  // Get layout data
  const layout = store.getters['layout/getLayout']

  // Get layout scripts and environment
  const {
    scripts,
    environment: { environment }
  } = layout || {}

  // Get script configs
  const { scripts: enabled, google_analytics_id } = scripts

  // Should insert script on page?
  const insertScript =
    enabled === '1' &&
    environment !== 'homolog' &&
    google_analytics_id &&
    cookieActived

  // Handle script not inserted
  if (!insertScript) {
    console.log('Google Analytics script not inserted!')
    return
  }

  // Insert GTag
  Vue.use(VueGtag, {
    config: {
      id: google_analytics_id,
      params: {
        send_page_view: true
      }
    }
  })

  // Log
  console.log('Google Analytics script inserted!')
}
