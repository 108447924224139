//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      most_accessed_products: [],
      most_accessed_applications: [],
      publications: []
    }
  },

  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const response = await this.$api.home.showSearchModal()

        const {
          most_accessed_products,
          most_accessed_applications,
          publications
        } = response

        this.most_accessed_products = most_accessed_products
        this.most_accessed_applications = most_accessed_applications
        this.publications = publications
      } catch (err) {
        this.$api.instance.handleResponseError(err)
      }
    }
  }
}
