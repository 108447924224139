// Dependencies
import ApiService from './api.service'

/**
 * Management of Home page
 * @export
 * @class HomeService
 * @extends {ApiService}
 */

export default class HomeService extends ApiService {
  /**
   * Creates an instance of HomeService.
   * @param {Object} { $axios }
   * @memberof HomeService
   */
  constructor({ $axios, store }) {
    super({ $axios })
    this.store = store
  }

  /**
   * Show home resource
   * @return {Response}
   * @memberof HomeService
   */
  async show() {
    try {
      const url = '/web/home'
      const response = await this.get({ url })

      const { popups } = response

      if (popups) {
        await this.store.dispatch('popup/storePopup', popups)
      }

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar os dados da home', err)

      throw err
    }
  }

  /**
   * Show search modal resource
   * @return {Response}
   * @memberof HomeService
   */
  async showSearchModal() {
    try {
      const url = '/web/home/search'
      const response = await this.get({ url })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar os dados do modal de busca')
      throw err
    }
  }
}
