//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Libraries
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      searchOpened: false,
      search: '',
      overflow: false
    }
  },

  computed: {
    ...mapGetters({
      layout: 'layout/getLayout',
      terms: 'layout/getTerms',
      privacy: 'layout/getPrivacy'
    }),

    privacy() {
      const { privacy } = this.layout
      return privacy || {}
    },

    navs() {
      let navs = [
        {
          title: this.$t('items.institutional'),
          to: '/institucional'
        },
        {
          title: this.$t('items.products'),
          to: '/produtos'
        },
        {
          title: this.$t('items.solutions'),
          to: '/solucoes'
        },
        {
          title: this.$t('items.news'),
          to: '/noticias'
        },
        {
          title: this.$t('items.knowlegde_base'),
          to: '/base-de-conhecimento'
        },
        {
          title: 'Área Restrita',
          to: '/area-restrita',
          mobile: true,
          language: 'br'
        },
        {
          title: 'Trabalhe Conosco',
          to: '/trabalhe-conosco',
          mobile: true,
          language: 'br'
        },
        {
          title: this.$t('items.contact'),
          to: '/contato'
        }
      ]

      if (this.$i18n.locale === 'br') {
        return navs
      } else {
        navs = navs.filter((item) => {
          return item?.language !== 'br'
        })

        return navs
      }
    }
  },

  watch: {
    $route() {
      if (this.searchOpened) {
        this.toggleSearch()
      }
    }
  },

  mounted() {
    this.onEscape()
  },

  methods: {
    onEscape() {
      document.addEventListener('keyup', (e) => {
        if (e.key === 'Escape' && this.searchOpened) {
          this.toggleSearch()
        }
      })
    },

    toggleSearch() {
      const html = document.getElementsByTagName('html')[0]

      this.searchOpened = !this.searchOpened
      this.overflow = !this.overflow

      if (this.overflow) {
        html.style.overflow = 'hidden'
      } else {
        html.style.overflow = 'auto'
      }

      if (this.searchOpened) {
        const searchParent = this.$refs.headerSearchInput
        const searchInput = searchParent.$refs.searchInput

        if (searchInput) {
          setTimeout(() => {
            searchInput.focus()
          }, 200)
        }
      }
    },

    onSearch() {
      if (this.search) {
        this.toggleSearch()
        this.$router.push({
          path: this.localePath('/busca'),
          query: {
            q: this.search
          }
        })
      }
    }
  }
}
