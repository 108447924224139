// Get system layout (base settings)
export default async function ({ $api, error }) {
  try {
    const { others } = await $api.layout.show()
    if (others && parseInt(others.environment_under_maintenance) === 1) {
      return error({ statusCode: 502 })
    }
  } catch (error) {
    console.log(`Ocorreu um erro ao executar o middleware 'layout': ${error}`)
  }
}
