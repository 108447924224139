/**
 * API Management Class
 * @export
 * @class ApiService
 */
export default class ApiService {
  /**
   * Creates an instance of ApiService.
   * @param {Object} { $axios, error }
   * @memberof ApiService
   */
  constructor({ $axios, error }) {
    this.$axios = $axios
    this.error = error
  }

  /**
   * Function to handle GET requests
   * @param {*} { url, params = {}, extraConfig }
   * @return {Promise}
   * @memberof ApiService
   */
  get({ url, params = {}, extraConfig }) {
    return this.request({
      url,
      method: 'GET',
      params,
      ...extraConfig
    })
  }

  /**
   * Function to handle POST requests
   * @param {*} { url, data, extraConfig = {} }
   * @return {Promise}
   * @memberof ApiService
   */
  post({ url, data, extraConfig = {} }) {
    return this.request({
      url,
      method: 'POST',
      data,
      ...extraConfig
    })
  }

  /**
   * Function to handle PUT requests
   * @param {*} { url, data, extraConfig = {} }
   * @return {Promise}
   * @memberof ApiService
   */
  put({ url, data, extraConfig = {} }) {
    return this.request({
      url,
      method: 'PUT',
      data,
      ...extraConfig
    })
  }

  /**
   * Function to handle DELETE requests
   * @param {*} { url, data, extraConfig = {} }
   * @return {Promise}
   * @memberof ApiService
   */
  delete({ url, data = {}, extraConfig = {} }) {
    return this.request({
      url,
      method: 'DELETE',
      data,
      ...extraConfig
    })
  }

  /**
   * Function to handle requests made to the API
   * @param {*} opts { url, method, data, axios configurations... }
   * @return {Response}
   * @memberof ApiService
   */
  async request(opts) {
    try {
      if (process.env.API_URL) {
        const response = await this.$axios(opts)
        const data = (response && response.data) || {}
        return data
      } else {
        console.warn('Váriavel de ambiente API_URL não setada no .env')
      }
    } catch (err) {
      const { skipError } = opts

      if (skipError) {
        throw err
      } else {
        const { url } = opts
        const { response } = err
        const message =
          response && response.data && response.data.error
            ? response.data.error.message
            : err.message

        console.log(
          `Houve um erro ao realizar a request para o endpoint: ${url} com a mensagem: ${message}`
        )

        throw err
      }
    }
  }

  /**
   * Function to handle errors returned from the API
   * @param {Error} error
   * @memberof ApiService
   */
  handleResponseError(error) {
    console.log('handleResponseError message: ', error)

    if (error.response) {
      if (error.response.status === 403) {
        // Access denied
        this.error({ statusCode: 403 })
      } else if (
        error.response.status === 404 ||
        error.response.status === 422
      ) {
        // Resource not found
        this.error({ statusCode: 404 })
      } else if (error.response.status === 500) {
        // Internal server error
        this.error({ statusCode: 500 })
      } else if (error.response.status === 502) {
        // Resource in maintenance
        this.error({ statusCode: 502 })
      }
    }
  }
}
