//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'vodal/common.css'
import Vodal from 'vodal'

export default {
  components: {
    Vodal
  },

  props: {
    config: {
      type: Object,
      default: () => {
        return {
          show: false,
          type: '',
          title: '',
          message: '',
          width: 494,
          close: true
        }
      }
    }
  },

  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name]
    }
  }
}
