//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Dependencies
import moment from 'moment'
import { mapGetters } from 'vuex'

// Components
import SocialMedia from '@/components/base/ui/SocialMedias'

export default {
  components: {
    SocialMedia
  },

  data() {
    return {
      year: '',
      menuItems: {
        left: [
          { label: this.$t('menu.items.home'), path: '/' },
          { label: this.$t('menu.items.institutional'), path: 'institucional' },
          { label: this.$t('menu.items.products'), path: 'produtos' },
          { label: this.$t('menu.items.solutions'), path: 'solucoes' }
        ],

        right: [
          { label: this.$t('menu.items.news'), path: 'noticias' },
          {
            label: this.$t('menu.items.knowlegde_base'),
            path: 'base-de-conhecimento'
          },
          { label: this.$t('menu.items.contact'), path: 'contato' },

          {
            label: 'Trabalhe Conosco',
            path: 'trabalhe-conosco',
            language: 'br'
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      contact: 'layout/getContact',
      terms: 'layout/getTerms',
      privacy: 'layout/getPrivacy',
      certificates: 'layout/getCertificates'
    }),

    hasCertificates() {
      let hasCertificates = false

      if (this.certificates && this.certificates.length > 0) {
        this.certificates.forEach((category) => {
          if (category.certificates && category.certificates.length > 0) {
            hasCertificates = true
          }
        })
      }

      return hasCertificates
    },

    filteredMenuItems() {
      const menuItems = this.menuItems

      if (this.$i18n.locale === 'br') {
        return menuItems
      } else {
        return {
          left: menuItems.left.filter((item) => {
            return item?.language !== 'br'
          }),

          right: menuItems.right.filter((item) => {
            return item?.language !== 'br'
          })
        }
      }
    }
  },

  mounted() {
    this.getYear()
  },

  methods: {
    getYear() {
      this.year = moment().year()
    }
  }
}
