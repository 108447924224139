// Dependencies
import ApiService from './api.service'

/**
 * Management of Solutions Data
 * @export
 * @class SolutionsService
 * @extends {ApiService}
 */
export default class SolutionsService extends ApiService {
  /**
   * Creates an instance of SolutionsService.
   * @param {Object} { $axios }
   * @memberof SolutionsService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Show all solutions resources
   * @param {FormData} params
   * @return {Response}
   * @memberof SolutionsService
   */
  async showAll(params) {
    try {
      const url = '/web/solutions'
      const response = await this.get({
        url,
        params
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar as soluções')
      throw err
    }
  }

  /**
   * Show a solution resource
   * @param {*} slug
   * @return {Response}
   * @memberof SolutionsService
   */
  async show(slug) {
    try {
      const url = `/web/solutions/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar a solução')
      throw err
    }
  }

  /**
   * Show a application resource
   * @param {*} slug
   * @return {Response}
   * @memberof SolutionsService
   */
  async application(slug) {
    try {
      const url = `/web/application/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar a aplicação')
      throw err
    }
  }
}
