//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      hideAlert: false
    }
  },

  computed: {
    ...mapGetters({
      layout: 'layout/getLayout'
    }),

    environment() {
      const { environment } = this.layout
      const { environment: status } = environment
      return status
    }
  },

  mounted() {
    this.init()
    this.addBodyClass()
  },

  methods: {
    init() {
      setTimeout(() => {
        this.hideAlert = true
      }, 3000)
    },

    addBodyClass() {
      const bodyClass =
        this.environment === 'homolog' ? 'is-homolog' : 'is-production'
      document.body.classList.add(bodyClass)
    }
  }
}
