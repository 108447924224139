// Store to handle and control the modals of the system
export const state = () => ({
  defaultOptions: {
    show: false,
    type: '',
    title: '',
    message: '',
    width: 494,
    close: true,
    actionUrl: '',
    actionLabel: 'Voltar'
  },
  config: null
})

export const mutations = {
  setConfig(state, config) {
    state.config = config
  }
}

export const actions = {
  show({ commit, state }, customOptions) {
    try {
      const config = {
        ...state.defaultOptions,
        ...customOptions,
        show: true
      }

      commit('setConfig', config)
    } catch (error) {
      console.log(error)
    }
  },

  hide({ commit, state }) {
    try {
      const config = {
        ...state.config,
        show: false
      }

      commit('setConfig', config)
    } catch (error) {
      console.log(error)
    }
  }
}

export const getters = {
  getConfig(state) {
    return state.config || state.defaultOptions
  }
}
