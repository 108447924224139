//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    application: {
      type: Object,
      required: true
    },

    basePath: {
      type: String,
      required: true
    },

    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    applicationPath() {
      const { solution, slug } = this.application
      const solutionSlug = solution ? solution.slug : 'sem-solucao'

      return this.localePath(`${this.basePath}/${solutionSlug}/${slug}`)
    }
  }
}
