// Dependencies
import ApiService from './api.service'

/**
 * Management of RestrictArea component
 * @export
 * @class RestrictAreaService
 * @extends {ApiService}
 */
export default class RestrictAreaService extends ApiService {
  /**
   * Creates an instance of RestrictAreaService.
   * @param {Object} { $axios }
   * @memberof RestrictAreaService
   */
  constructor({ $axios, store }) {
    super({ $axios })
    this.store = store
  }

  /**
   * Login user
   * @param {FormData} data
   * @return {Response}
   * @memberof RestrictAreaService
   */
  async login(data) {
    try {
      const url = '/web/restricted-area/login'
      const response = await this.post({
        url,
        data
      })

      await this.store.dispatch('auth/login', response)

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao realizar o login do usuário')
      throw err
    }
  }

  /**
   * Get whoim
   * @return {Response}
   * @memberof RestrictAreaService
   */
  async whoim() {
    try {
      const url = '/web/restricted-area/whoiam'
      const response = await this.get({
        url,
        extraConfig: {
          skipError: true
        }
      })

      await this.store.dispatch('auth/whoim', response)

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar os dados do usuário')
      throw err
    }
  }

  /**
   * Check user
   * @param {FormData} data
   * @return {Response}
   * @memberof RestrictAreaService
   */
  async checkUser(data) {
    try {
      const url = '/web/restricted-area/check'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log(
        'Ocorreu um erro ao realizar a checkagem dos dados de acesso do usuário'
      )
      throw err
    }
  }

  /**
   * Register user
   * @param {FormData} data
   * @return {Response}
   * @memberof RestrictAreaService
   */
  async register(data) {
    try {
      const url = '/web/restricted-area/create'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao realizar o cadastro do usuário')
      throw err
    }
  }

  /**
   * Recovery password
   * @param {FormData} data
   * @return {Response}
   * @memberof RestrictAreaService
   */
  async recoveryPassword(data) {
    try {
      const url = '/web/restricted-area/recovery'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log(
        'Ocorreu um erro ao realizar o envio de e-mail de recuperação de senha'
      )
      throw err
    }
  }

  /**
   * Update password
   * @param {FormData} data
   * @return {Response}
   * @memberof RestrictAreaService
   */
  async updatePassword(data) {
    try {
      const url = '/web/restricted-area/update-password'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao realizar alterar a senha do usuário')
      throw err
    }
  }

  /**
   * Update user
   * @param {FormData} data
   * @return {Response}
   * @memberof RestrictAreaService
   */
  async updateUser(data) {
    try {
      const url = '/web/restricted-area/user/update'
      const response = await this.post({
        url,
        data
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao alterar os dados do usuário')
      throw err
    }
  }
}
