// Dependencies
import Vue from 'vue'
import striptags from 'striptags'
import * as entities from 'entities'
import slugify from 'slugify'

// Check if navigator is equal IE 11 or older
export const isIe = () => {
  const ua = window.navigator.userAgent // Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE ') // IE 10 or older
  const trident = ua.indexOf('Trident/') // IE 11
  return msie > 0 || trident > 0
}

// Build page SEO using Vue Meta
export const seoBuilder = (context, options) => {
  const { $store } = context || {}
  const {
    staticTitle,
    staticDescription,
    bodyAttrs,
    ogImage,
    ogImageWidth,
    ogImageHeight
  } = options || {}
  const seo = $store.getters['seo/getSeo']
  const title = seo.title || staticTitle
  const pageTitle = title ? `${title}` : process.env.PROJECT_NAME
  const pageDescription = seo.description || staticDescription
  const head = {
    title: pageTitle,
    meta: [
      {
        hid: 'og:title',
        property: 'og:title',
        content: pageTitle
      }
    ]
  }

  if (pageDescription) {
    head.meta.push({
      hid: 'description',
      name: 'description',
      content: pageDescription
    })

    head.meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: pageDescription
    })
  }

  if (ogImage) {
    head.meta.push({
      hid: 'og:image',
      property: 'og:image',
      content: ogImage
    })
  }

  if (ogImageWidth) {
    head.meta.push({
      hid: 'og:image:width',
      property: 'og:image:width',
      content: ogImageWidth
    })
  }

  if (ogImageHeight) {
    head.meta.push({
      hid: 'og:image:height',
      property: 'og:image:height',
      content: ogImageHeight
    })
  }

  if (bodyAttrs) {
    head.bodyAttrs = bodyAttrs
  }

  return head
}

// Show modal
export const showModal = (store, config) => {
  store.dispatch('modal/show', config)
}

// Hide modal
export const hideModal = (store) => {
  store.dispatch('modal/hide')
}

// Strip text
export const stripText = (text) => {
  if (text) {
    const strippedText = striptags(text)
    const strippedHtmlEntities = entities.decodeHTML(strippedText)
    return strippedHtmlEntities
  }
  return text
}

// Truncate text
export const truncateText = (text, length) => {
  if (text) {
    const strippedText = striptags(text)
    const strippedHtmlEntities = entities.decodeHTML(strippedText)
    return Vue.options.filters.truncate(strippedHtmlEntities, length)
  }
  return text
}

// Slugify text
export const slugifyText = (text, options) => {
  return slugify(text, {
    replacement: '-',
    strict: true,
    remove: /[^a-zA-Z\d\s:-]/,
    lower: true,
    ...options
  })
}

// Find cookie preference
export const findCookiePreference = (cookieSlug) => {
  const savedPreferences = window.localStorage.getItem(
    `${process.env.STORAGE_PREFIX}cookies-usage-accepted`
  )
  let cookiePreferenceFounded = null

  if (savedPreferences) {
    const savedPreferencesParsed = JSON.parse(savedPreferences)
    const { preferences } = savedPreferencesParsed

    if (preferences && preferences.length > 0) {
      preferences.forEach((category) => {
        category.items.forEach((cookie) => {
          if (cookie.slug === cookieSlug) {
            cookiePreferenceFounded = cookie
          }
        })
      })
    }
  }

  return cookiePreferenceFounded
}

// Get YT video ID
export const getYTVideoId = (videoUrl) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/ // eslint-disable-line
  const match = videoUrl.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}

// Has youtube thumbnail
export const getYTThumbnail = (videoUrl) => {
  const url = `https://img.youtube.com/vi/${getYTVideoId(
    videoUrl
  )}/maxresdefault.jpg`

  return url
}

// Inject the helper's, making it available in the context, component, store, etc.
export default (ctx, inject) => {
  const helpers = {
    isIe,
    seoBuilder,
    showModal,
    hideModal,
    stripText,
    truncateText,
    slugifyText,
    findCookiePreference,
    getYTVideoId,
    getYTThumbnail
  }

  ctx.$helpers = helpers
  inject('helpers', helpers)
}
