// Dependencies
import ApiService from './api.service'

/**
 * Management of Sections Data
 * @export
 * @class SectionsService
 * @extends {ApiService}
 */
export default class SectionsService extends ApiService {
  /**
   * Creates an instance of SectionsService.
   * @param {Object} { $axios }
   * @memberof SectionsService
   */
  constructor({ $axios }) {
    super({ $axios })
  }

  /**
   * Show all sections resources
   * @param {FormData} params
   * @return {Response}
   * @memberof SectionsService
   */
  async showAll(params) {
    try {
      const url = '/web/restricted-area/sections'
      const response = await this.get({
        url,
        params
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar as seções')
      throw err
    }
  }

  /**
   * Show a section resource
   * @param {*} slug
   * @return {Response}
   * @memberof SectionsService
   */
  async show(slug) {
    try {
      const url = `/web/restricted-area/sections/${slug}`
      const response = await this.get({
        url
      })

      return response
    } catch (err) {
      console.log('Ocorreu um erro ao recuperar a seção')
      throw err
    }
  }
}
