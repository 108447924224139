// Store to store website data, such as configuration data, privacy terms, terms of use, cookies alert and more
export const state = () => {
  return {
    data: {
      contact: {},
      cookies: {},
      emails: {},
      environment: {},
      privacy: {},
      scripts: {},
      social_network: {},
      others: {}
    }
  }
}

export const mutations = {
  SET_DATA(state, data) {
    state.data = data
  }
}

export const actions = {
  storeLayout({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      try {
        if (data) {
          const {
            terms_of_user_en,
            terms_of_user_es,
            terms_of_user,
            privacy_policy_en,
            privacy_policy_es,
            privacy_policy
          } = data.privacy

          if (this.app.i18n.locale === 'en') {
            data.privacy.terms_of_user = terms_of_user_en
            data.privacy.privacy_policy = privacy_policy_en
          } else if (this.app.i18n.locale === 'es') {
            data.privacy.terms_of_user = terms_of_user_es
            data.privacy.privacy_policy = privacy_policy_es
          } else {
            data.privacy.terms_of_user = terms_of_user
            data.privacy.privacy_policy = privacy_policy
          }

          commit('SET_DATA', {
            ...state.data,
            ...data
          })
        }
        resolve(true)
      } catch (err) {
        console.log('Ocorreu um erro ao setar os dados do layout')
        reject(err)
      }
    })
  }
}

export const getters = {
  getLayout(state) {
    return state.data
  },

  getTerms(state) {
    return state.data.privacy.terms_of_user
  },

  getPrivacy(state) {
    return state.data.privacy.privacy_policy
  },

  getContact(state) {
    return state.data.contact
  },

  getCertificates(state) {
    return state.data.certificates
  }
}
