//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { values, isEmpty } from 'lodash'

export default {
  props: {
    title: {
      type: String,
      default: 'Acesse nossas redes sociais: '
    }
  },

  computed: {
    ...mapGetters({
      layout: 'layout/getLayout'
    }),

    hasSocialMedias() {
      if (this.socialMedias) {
        return !values(this.socialMedias).every(isEmpty)
      }

      return false
    },

    socialMedias() {
      const { social_network } = this.layout
      return social_network
    }
  }
}
