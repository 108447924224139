//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      modalLogout: {
        show: false,
        type: '',
        title: '',
        message: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin'
    }),

    navs() {
      const navs = [
        {
          title: 'Seções',
          to: '/area-restrita/secoes'
        }
      ]

      if (!this.isAdmin) {
        navs.push({
          title: 'Meus Dados',
          to: '/area-restrita/meus-dados'
        })
      }

      return navs
    }
  },

  methods: {
    handleLogout(event) {
      event.preventDefault()

      this.modalLogout = {
        show: true,
        type: 'warning',
        title: 'Confirmação',
        message: 'Tem certeza que deseja sair do sistema?'
      }
    }
  }
}
