import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _52f24208 = () => interopDefault(import('../pages/area-restrita/index.vue' /* webpackChunkName: "pages/area-restrita/index" */))
const _68ab4a2e = () => interopDefault(import('../pages/base-de-conhecimento/index.vue' /* webpackChunkName: "pages/base-de-conhecimento/index" */))
const _5f0b9d62 = () => interopDefault(import('../pages/busca/index.vue' /* webpackChunkName: "pages/busca/index" */))
const _4a8f936e = () => interopDefault(import('../pages/contato/index.vue' /* webpackChunkName: "pages/contato/index" */))
const _9706e3da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cfc74150 = () => interopDefault(import('../pages/institucional/index.vue' /* webpackChunkName: "pages/institucional/index" */))
const _0b39ac20 = () => interopDefault(import('../pages/noticias/index.vue' /* webpackChunkName: "pages/noticias/index" */))
const _bdca60b6 = () => interopDefault(import('../pages/politicas-de-privacidade/index.vue' /* webpackChunkName: "pages/politicas-de-privacidade/index" */))
const _2badbca8 = () => interopDefault(import('../pages/produtos/index.vue' /* webpackChunkName: "pages/produtos/index" */))
const _7cb4415e = () => interopDefault(import('../pages/solucoes/index.vue' /* webpackChunkName: "pages/solucoes/index" */))
const _6e9bbc06 = () => interopDefault(import('../pages/styleguide/index.vue' /* webpackChunkName: "pages/styleguide/index" */))
const _16e1abe6 = () => interopDefault(import('../pages/termos-de-uso/index.vue' /* webpackChunkName: "pages/termos-de-uso/index" */))
const _7bc5179e = () => interopDefault(import('../pages/trabalhe-conosco/index.vue' /* webpackChunkName: "pages/trabalhe-conosco/index" */))
const _736ae93c = () => interopDefault(import('../pages/area-restrita/alterar-senha/index.vue' /* webpackChunkName: "pages/area-restrita/alterar-senha/index" */))
const _b05f507c = () => interopDefault(import('../pages/area-restrita/cadastro/index.vue' /* webpackChunkName: "pages/area-restrita/cadastro/index" */))
const _3d3f37ec = () => interopDefault(import('../pages/area-restrita/esqueci-minha-senha/index.vue' /* webpackChunkName: "pages/area-restrita/esqueci-minha-senha/index" */))
const _52597353 = () => interopDefault(import('../pages/area-restrita/logout/index.vue' /* webpackChunkName: "pages/area-restrita/logout/index" */))
const _63bb50c6 = () => interopDefault(import('../pages/area-restrita/meus-dados/index.vue' /* webpackChunkName: "pages/area-restrita/meus-dados/index" */))
const _634e2eb5 = () => interopDefault(import('../pages/area-restrita/secoes/index.vue' /* webpackChunkName: "pages/area-restrita/secoes/index" */))
const _33942113 = () => interopDefault(import('../pages/area-restrita/secoes/_section.vue' /* webpackChunkName: "pages/area-restrita/secoes/_section" */))
const _67fbec06 = () => interopDefault(import('../pages/base-de-conhecimento/_section/index.vue' /* webpackChunkName: "pages/base-de-conhecimento/_section/index" */))
const _74e0a36e = () => interopDefault(import('../pages/noticias/_category/index.vue' /* webpackChunkName: "pages/noticias/_category/index" */))
const _b058cc0c = () => interopDefault(import('../pages/produtos/_category/index.vue' /* webpackChunkName: "pages/produtos/_category/index" */))
const _68f07fcc = () => interopDefault(import('../pages/solucoes/_solution/index.vue' /* webpackChunkName: "pages/solucoes/_solution/index" */))
const _f8569cdc = () => interopDefault(import('../pages/base-de-conhecimento/_section/_publication.vue' /* webpackChunkName: "pages/base-de-conhecimento/_section/_publication" */))
const _4c4353de = () => interopDefault(import('../pages/noticias/_category/_post/index.vue' /* webpackChunkName: "pages/noticias/_category/_post/index" */))
const _33b5404a = () => interopDefault(import('../pages/produtos/_category/_subcategory/index.vue' /* webpackChunkName: "pages/produtos/_category/_subcategory/index" */))
const _2d1281f9 = () => interopDefault(import('../pages/solucoes/_solution/_application.vue' /* webpackChunkName: "pages/solucoes/_solution/_application" */))
const _e04e062a = () => interopDefault(import('../pages/produtos/_category/_subcategory/_product/index.vue' /* webpackChunkName: "pages/produtos/_category/_subcategory/_product/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/area-restrita",
    component: _52f24208,
    name: "area-restrita___br"
  }, {
    path: "/base-de-conhecimento",
    component: _68ab4a2e,
    name: "base-de-conhecimento___br"
  }, {
    path: "/busca",
    component: _5f0b9d62,
    name: "busca___br"
  }, {
    path: "/contato",
    component: _4a8f936e,
    name: "contato___br"
  }, {
    path: "/en",
    component: _9706e3da,
    name: "index___en"
  }, {
    path: "/es",
    component: _9706e3da,
    name: "index___es"
  }, {
    path: "/institucional",
    component: _cfc74150,
    name: "institucional___br"
  }, {
    path: "/noticias",
    component: _0b39ac20,
    name: "noticias___br"
  }, {
    path: "/politicas-de-privacidade",
    component: _bdca60b6,
    name: "politicas-de-privacidade___br"
  }, {
    path: "/produtos",
    component: _2badbca8,
    name: "produtos___br"
  }, {
    path: "/solucoes",
    component: _7cb4415e,
    name: "solucoes___br"
  }, {
    path: "/styleguide",
    component: _6e9bbc06,
    name: "styleguide___br"
  }, {
    path: "/termos-de-uso",
    component: _16e1abe6,
    name: "termos-de-uso___br"
  }, {
    path: "/trabalhe-conosco",
    component: _7bc5179e,
    name: "trabalhe-conosco___br"
  }, {
    path: "/area-restrita/alterar-senha",
    component: _736ae93c,
    name: "area-restrita-alterar-senha___br"
  }, {
    path: "/area-restrita/cadastro",
    component: _b05f507c,
    name: "area-restrita-cadastro___br"
  }, {
    path: "/area-restrita/esqueci-minha-senha",
    component: _3d3f37ec,
    name: "area-restrita-esqueci-minha-senha___br"
  }, {
    path: "/area-restrita/logout",
    component: _52597353,
    name: "area-restrita-logout___br"
  }, {
    path: "/area-restrita/meus-dados",
    component: _63bb50c6,
    name: "area-restrita-meus-dados___br"
  }, {
    path: "/area-restrita/secoes",
    component: _634e2eb5,
    name: "area-restrita-secoes___br"
  }, {
    path: "/en/about-us",
    component: _cfc74150,
    name: "institucional___en"
  }, {
    path: "/en/contact",
    component: _4a8f936e,
    name: "contato___en"
  }, {
    path: "/en/knowledge-base",
    component: _68ab4a2e,
    name: "base-de-conhecimento___en"
  }, {
    path: "/en/news",
    component: _0b39ac20,
    name: "noticias___en"
  }, {
    path: "/en/privacy-policies",
    component: _bdca60b6,
    name: "politicas-de-privacidade___en"
  }, {
    path: "/en/products",
    component: _2badbca8,
    name: "produtos___en"
  }, {
    path: "/en/restricted-area",
    component: _52f24208,
    name: "area-restrita___en"
  }, {
    path: "/en/search",
    component: _5f0b9d62,
    name: "busca___en"
  }, {
    path: "/en/solutions",
    component: _7cb4415e,
    name: "solucoes___en"
  }, {
    path: "/en/styleguide",
    component: _6e9bbc06,
    name: "styleguide___en"
  }, {
    path: "/en/terms-of-use",
    component: _16e1abe6,
    name: "termos-de-uso___en"
  }, {
    path: "/en/trabalhe-conosco",
    component: _7bc5179e,
    name: "trabalhe-conosco___en"
  }, {
    path: "/es/area-restringida",
    component: _52f24208,
    name: "area-restrita___es"
  }, {
    path: "/es/base-de-conocimientos",
    component: _68ab4a2e,
    name: "base-de-conhecimento___es"
  }, {
    path: "/es/buscar",
    component: _5f0b9d62,
    name: "busca___es"
  }, {
    path: "/es/contacto",
    component: _4a8f936e,
    name: "contato___es"
  }, {
    path: "/es/institucional",
    component: _cfc74150,
    name: "institucional___es"
  }, {
    path: "/es/noticias",
    component: _0b39ac20,
    name: "noticias___es"
  }, {
    path: "/es/politicas-de-privacidad",
    component: _bdca60b6,
    name: "politicas-de-privacidade___es"
  }, {
    path: "/es/productos",
    component: _2badbca8,
    name: "produtos___es"
  }, {
    path: "/es/soluciones",
    component: _7cb4415e,
    name: "solucoes___es"
  }, {
    path: "/es/styleguide",
    component: _6e9bbc06,
    name: "styleguide___es"
  }, {
    path: "/es/terminos-de-uso",
    component: _16e1abe6,
    name: "termos-de-uso___es"
  }, {
    path: "/es/trabalhe-conosco",
    component: _7bc5179e,
    name: "trabalhe-conosco___es"
  }, {
    path: "/en/restricted-area/forgot-my-password",
    component: _3d3f37ec,
    name: "area-restrita-esqueci-minha-senha___en"
  }, {
    path: "/en/restricted-area/logout",
    component: _52597353,
    name: "area-restrita-logout___en"
  }, {
    path: "/en/restricted-area/my-data",
    component: _63bb50c6,
    name: "area-restrita-meus-dados___en"
  }, {
    path: "/en/restricted-area/register",
    component: _b05f507c,
    name: "area-restrita-cadastro___en"
  }, {
    path: "/en/restricted-area/sections",
    component: _634e2eb5,
    name: "area-restrita-secoes___en"
  }, {
    path: "/en/restricted-area/update-password",
    component: _736ae93c,
    name: "area-restrita-alterar-senha___en"
  }, {
    path: "/es/area-restringida/cambiar-contrasena",
    component: _736ae93c,
    name: "area-restrita-alterar-senha___es"
  }, {
    path: "/es/area-restringida/logout",
    component: _52597353,
    name: "area-restrita-logout___es"
  }, {
    path: "/es/area-restringida/mis-datos",
    component: _63bb50c6,
    name: "area-restrita-meus-dados___es"
  }, {
    path: "/es/area-restringida/olvide-mi-contrasena",
    component: _3d3f37ec,
    name: "area-restrita-esqueci-minha-senha___es"
  }, {
    path: "/es/area-restringida/registro",
    component: _b05f507c,
    name: "area-restrita-cadastro___es"
  }, {
    path: "/es/area-restringida/secciones",
    component: _634e2eb5,
    name: "area-restrita-secoes___es"
  }, {
    path: "/en/restricted-area/sections/:section",
    component: _33942113,
    name: "area-restrita-secoes-section___en"
  }, {
    path: "/es/area-restringida/secciones/:section",
    component: _33942113,
    name: "area-restrita-secoes-section___es"
  }, {
    path: "/area-restrita/secoes/:section",
    component: _33942113,
    name: "area-restrita-secoes-section___br"
  }, {
    path: "/en/knowledge-base/:section",
    component: _67fbec06,
    name: "base-de-conhecimento-section___en"
  }, {
    path: "/en/news/:category",
    component: _74e0a36e,
    name: "noticias-category___en"
  }, {
    path: "/en/products/:category",
    component: _b058cc0c,
    name: "produtos-category___en"
  }, {
    path: "/en/solutions/:solution",
    component: _68f07fcc,
    name: "solucoes-solution___en"
  }, {
    path: "/es/base-de-conocimientos/:section",
    component: _67fbec06,
    name: "base-de-conhecimento-section___es"
  }, {
    path: "/es/noticias/:category",
    component: _74e0a36e,
    name: "noticias-category___es"
  }, {
    path: "/es/productos/:category",
    component: _b058cc0c,
    name: "produtos-category___es"
  }, {
    path: "/es/soluciones/:solution",
    component: _68f07fcc,
    name: "solucoes-solution___es"
  }, {
    path: "/en/knowledge-base/:section/:publication",
    component: _f8569cdc,
    name: "base-de-conhecimento-section-publication___en"
  }, {
    path: "/en/news/:category/:post",
    component: _4c4353de,
    name: "noticias-category-post___en"
  }, {
    path: "/en/products/:category/:subcategory",
    component: _33b5404a,
    name: "produtos-category-subcategory___en"
  }, {
    path: "/en/solutions/:solution/:application",
    component: _2d1281f9,
    name: "solucoes-solution-application___en"
  }, {
    path: "/es/base-de-conocimientos/:section/:publication",
    component: _f8569cdc,
    name: "base-de-conhecimento-section-publication___es"
  }, {
    path: "/es/noticias/:category/:post",
    component: _4c4353de,
    name: "noticias-category-post___es"
  }, {
    path: "/es/productos/:category/:subcategory",
    component: _33b5404a,
    name: "produtos-category-subcategory___es"
  }, {
    path: "/es/soluciones/:solution/:application",
    component: _2d1281f9,
    name: "solucoes-solution-application___es"
  }, {
    path: "/en/products/:category/:subcategory/:product",
    component: _e04e062a,
    name: "produtos-category-subcategory-product___en"
  }, {
    path: "/es/productos/:category/:subcategory/:product",
    component: _e04e062a,
    name: "produtos-category-subcategory-product___es"
  }, {
    path: "/base-de-conhecimento/:section",
    component: _67fbec06,
    name: "base-de-conhecimento-section___br"
  }, {
    path: "/noticias/:category",
    component: _74e0a36e,
    name: "noticias-category___br"
  }, {
    path: "/produtos/:category",
    component: _b058cc0c,
    name: "produtos-category___br"
  }, {
    path: "/solucoes/:solution",
    component: _68f07fcc,
    name: "solucoes-solution___br"
  }, {
    path: "/base-de-conhecimento/:section/:publication",
    component: _f8569cdc,
    name: "base-de-conhecimento-section-publication___br"
  }, {
    path: "/noticias/:category/:post",
    component: _4c4353de,
    name: "noticias-category-post___br"
  }, {
    path: "/produtos/:category/:subcategory",
    component: _33b5404a,
    name: "produtos-category-subcategory___br"
  }, {
    path: "/solucoes/:solution/:application",
    component: _2d1281f9,
    name: "solucoes-solution-application___br"
  }, {
    path: "/produtos/:category/:subcategory/:product",
    component: _e04e062a,
    name: "produtos-category-subcategory-product___br"
  }, {
    path: "/",
    component: _9706e3da,
    name: "index___br"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
