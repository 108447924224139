export const actions = {
  // https://nuxtjs.org/docs/directory-structure/store
  // https://nuxtjs.org/docs/directory-structure/store/#the-nuxtserverinit-action
  nuxtServerInit({ commit, dispatch }, { req, $cookies }) {
    // Store initials popups
    dispatch('popup/storeInitialsPopups')

    // Store user token
    if (req.headers.cookie) {
      try {
        const auth = $cookies.get(
          `${process.env.STORAGE_PREFIX}authorizationToken`
        )
        if (auth) {
          commit('auth/SET_AUTH', auth)
        }
      } catch (err) {
        console.log(
          'Ocorreu um erro ao setar o token de autorização do usuário: ',
          err
        )
      }
    }
  }
}
