//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  props: {
    product: {
      type: Object,
      required: true
    },

    basePath: {
      type: String,
      required: true
    },

    list: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    subcategoryPath() {
      const { category, sub_category } = this.product
      const subCategorySlug = sub_category
        ? sub_category.slug
        : 'sem-subcategoria'
      const categorySlug = category ? category.slug : 'sem-categoria'

      return this.localePath(
        `${this.basePath}/${categorySlug}/${subCategorySlug}`
      )
    },

    productPath() {
      const { category, sub_category, slug } = this.product
      const subCategorySlug = sub_category
        ? sub_category.slug
        : 'sem-subcategoria'
      const categorySlug = category ? category.slug : 'sem-categoria'

      return this.localePath(
        `${this.basePath}/${categorySlug}/${subCategorySlug}/${slug}`
      )
    }
  }
}
