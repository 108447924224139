// object-fit polyfill
import objectFitImages from 'object-fit-images'
objectFitImages()

// forEach polyfill
if (!Object.getOwnPropertyDescriptor(NodeList.prototype, 'forEach')) {
  Object.defineProperty(
    NodeList.prototype,
    'forEach',
    Object.getOwnPropertyDescriptor(Array.prototype, 'forEach')
  )
}

// matches() polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

// closest() polyfill
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    let el = this
    do {
      if (Element.prototype.matches.call(el, s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}
